import React from "react"
import styled from "styled-components"
import HomeScreenOne from "../components/home-screens/HomeScreenOne"
import HomeScreenGeneric from "../components/home-screens/HomeScreenGeneric"
import FakePopup from "../components/FakePopup"
import { imagePromise } from "../utils/imagePromise"
import Loading from "../components/Loading"
import background from "../assets/pen-instore-background.jpg"
import searchImg from "../assets/pen-search.png"
import clickImg from "../assets/pen-intro-sweets.png"
import santaImg from "../assets/pen-intro-santa.png"

const HomeStyles = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: green;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .home-background {
    display: block;
    object-fit: cover;
    pointer-events: none;
    touch-action: none;
    height: 100%;
    width: 100%;
  }
`

export default function Home({ data }) {
  const [loading, setLoading] = React.useState(true)
  const [pagination, setPagination] = React.useState(0)

  React.useEffect(() => {
    imagePromise(setLoading)
  }, [])

  return (
    <>
      <HomeStyles>
        <img className="home-background" src={background} alt="" />
        <FakePopup loading={loading}>
          {pagination === 0 ? (
            <HomeScreenOne setPagination={setPagination} />
          ) : pagination === 1 ? (
            <HomeScreenGeneric
              pagination={pagination}
              setPagination={setPagination}
              title="Search"
              image={searchImg}
              copy="Drag the map to search for the hidden sweets."
            />
          ) : pagination === 2 ? (
            <HomeScreenGeneric
              pagination={pagination}
              setPagination={setPagination}
              title="Click or Tap"
              image={clickImg}
              copy="When you find the sweets in question, click or tap on them to earn points."
            />
          ) : pagination === 3 ? (
            <HomeScreenGeneric
              pagination={pagination}
              setPagination={setPagination}
              title="Win The Scented Bonanza!"
              image={santaImg}
              copy="Find the gummy Father Christmas to win a scented bonanza beyond your wildest dreams!"
            />
          ) : (
            <HomeScreenOne setPagination={setPagination} />
          )}
        </FakePopup>
      </HomeStyles>
      {loading && <Loading />}
    </>
  )
}
